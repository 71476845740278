import moment from "moment";
import { ToRoundDecimal, ToRoundDecimalTemperature } from "./components/Constants";
import { v4 } from "uuid";
import { ENV } from "../../Constants";
/**
 * INSIGHTS_PAGE_SELECTORS_OF_SOURCE
 *
 * **/
export const PAGE_SELECTORS_OF_SOURCE = [
  {
    key: "ENERGY",
    value: "ENERGY"
  },
  {
    key: "WATER",
    value: "WATER"
  },
  {
    key: "IAQ",
    value: "IAQ"
  }
];
/**
 * PAGE SELECTORS OF STUDENT SOURCE
 *
 * **/
export const PAGE_SELECTORS_OF_STUDENT_SOURCE = [
  {
    key: "ENERGY",
    value: "ENERGY"
  },
  {
    key: "WATER",
    value: "WATER"
  }
];
/**
 * PAGE UNIT By source type
 *
 * **/
export const PAGE_SELECTORS_OF_UNIT_BY_SOURCE = {
  ENERGY: [
    {
      key: "ENERGY",
      value: "kWh"
    },
    {
      key: "E_COST",
      value: "$"
    },
    {
      key: "E_CO2",
      value: "Kg"
    },
    {
      key: "E_EUI",
      value: "kWh/m2"
    }
  ],
  WATER: [
    {
      key: "WATER",
      value: "L"
    },
    {
      key: "W_COST",
      value: "$"
    },
    {
      key: "W_CO2",
      value: "Kg"
    },
    {
      key: "W_EUI",
      value: "L/m2"
    }
  ],
  // TEMPERATURE: [
  //   {
  //     key: "TEMPERATURE",
  //     value: "℃"
  //   }
  // ],
  IAQ: [
    {
      key: "IAQ_TEMPERATURE",
      value: "℃"
    },
    {
      key: "IAQ_HUMIDITY",
      value: "%"
    },
    {
      key: "IAQ_TVOC",
      value: "μg/m³"
    },
    {
      key: "IAQ_CO2",
      value: "μg/m³"
    },
    {
      key: "IAQ_PM25",
      value: "μg/m³"
    }
  ]
};
/**
 * PAGE ALERTS SELECTORS OF UNIT BY SOURCE
 *
 * **/
export const PAGE_ALERTS_SELECTORS_OF_UNIT_BY_SOURCE = {
  ENERGY: [
    {
      key: "ENERGY",
      value: "kWh"
    }
  ],
  WATER: [
    {
      key: "WATER",
      value: "L"
    }
  ],
  TEMPERATURE: [
    {
      key: "TEMPERATURE",
      value: "℃"
    }
  ]
};
/**
 * Dashboard and Insights Selectors period option
 *
 * **/
export const PAGE_SELECTORS_OF_PERIOD = [
  {
    label: "RELATIVE DATES",
    title: "RELATIVE_DATES",
    options: [
      {
        label: "WDP_TODAY",
        value: "WDP_TODAY"
      },
      {
        label: "WDP_YESTERDAY",
        value: "WDP_YESTERDAY"
      }
    ]
  },
  {
    label: "RELATIVE PERIOD",
    title: "RELATIVE_PERIOD",
    options: [
      {
        label: "WDP_THISWEEK",
        value: "WDP_THISWEEK"
      },
      {
        label: "WDP_LASTWEEK",
        value: "WDP_LASTWEEK"
      },
      {
        label: "WDP_LAST2WEEK",
        value: "WDP_LAST2WEEK"
      },
      {
        label: "WDP_THISMONTH",
        value: "WDP_THISMONTH"
      },
      {
        label: "WDP_LASTMONTH",
        value: "WDP_LASTMONTH"
      },
      {
        label: "WDP_THISQUARTER",
        value: "WDP_THISQUARTER"
      },
      {
        label: "WDP_LASTQUARTER",
        value: "WDP_LASTQUARTER"
      },
      {
        label: "WDP_THISYEAR",
        value: "WDP_THISYEAR"
      },
      {
        label: "WDP_LASTYEAR",
        value: "WDP_LASTYEAR"
      },
      {
        label: "WDP_MONTHTODATE",
        value: "WDP_MONTHTODATE"
      },
      {
        label: "WDP_QUARTERTODATE",
        value: "WDP_QUARTERTODATE"
      },
      {
        label: "WDP_YEARTODATE",
        value: "WDP_YEARTODATE"
      }
    ]
  },
  {
    label: "ROLLING PERIOD",
    title: "ROLLING_PERIOD",
    options: [
      {
        label: "WDP_PAST7DAYS",
        value: "WDP_PAST7DAYS"
      },
      {
        label: "WDP_PAST2WEEKS",
        value: "WDP_PAST2WEEKS"
      },
      {
        label: "WDP_PASTMONTH",
        value: "WDP_PASTMONTH"
      },
      {
        label: "WDP_PAST3MONTH",
        value: "WDP_PAST3MONTH"
      },
      {
        label: "WDP_PAST6MONTH",
        value: "WDP_PAST6MONTH"
      },
      {
        label: "WDP_PAST12MONTH",
        value: "WDP_PAST12MONTH"
      },
      {
        label: "WDP_CUSTOM_PERIOD",
        value: "WDP_CUSTOM_PERIOD"
      }
    ]
  }
];
const PAGE_COMPARE_DAY_MORE_SELECTORS_OF_PERIOD = [
  {
    label: "Previous day",
    value: "WDP_PREVIOUSDAY"
  },
  {
    label: "Same day last week",
    value: "WDP_SAMEDAY_LASTWEEK"
  }
  // {
  //   label: "Same day two weeks before",
  //   value: "WDP_SAME_DAY_TWO_WEEKS_BEFORE"
  // },
  // {
  //   label: "Same day(s) last month",
  //   value: "WDP_SAME_DAY_LAST_MONTH"
  // }
];
const PAGE_COMPARE_WEEK_MORE_SELECTORS_OF_PERIOD = [
  {
    label: "Previous week",
    value: "WDP_PREVIOUSWEEK"
  }
  // {
  //   label: "A week ago1",
  //   value: "WDP_A_WEEK_AGO"
  // },
  // {
  //   label: "Two weeks ago",
  //   value: "WDP_TWO_WEEKS_AGO"
  // }
];
const PAGE_COMPARE_MONTH_MORE_SELECTORS_OF_PERIOD = [
  {
    label: "Previous month",
    value: "WDP_PREVIOUSMONTH"
  },
  {
    label: "Same month previous year",
    value: "WDP_SAMEMONTH_PREVIOUSYEAR"
  }
];
const PAGE_COMPARE_QUARTER_MORE_SELECTORS_OF_PERIOD = [
  {
    label: "Previous quarter",
    value: "WDP_PREVIOUSQUARTER"
  },
  {
    label: "Same quarter previous year",
    value: "WDP_SAMEQUARTER_PREVIOUSYEAR"
  }
];
const PAGE_COMPARE_YEAR_MORE_SELECTORS_OF_PERIOD = [
  {
    label: "Previous year",
    value: "WDP_PREVIOUSYEAR"
  }
];

/**
 * Period Relative Previous period
 *
 * **/
export const PAGE_COMPARE_SELECTORS_OF_PERIOD = {
  WDP_TODAY: PAGE_COMPARE_DAY_MORE_SELECTORS_OF_PERIOD,
  WDP_YESTERDAY: PAGE_COMPARE_DAY_MORE_SELECTORS_OF_PERIOD,
  WDP_THISWEEK: PAGE_COMPARE_WEEK_MORE_SELECTORS_OF_PERIOD,
  WDP_LASTWEEK: PAGE_COMPARE_WEEK_MORE_SELECTORS_OF_PERIOD,
  WDP_LAST2WEEK: [
    {
      label: "WDP_PREVIOUS2WEEK",
      value: "WDP_PREVIOUS2WEEK"
    }
  ],
  WDP_THISMONTH: PAGE_COMPARE_MONTH_MORE_SELECTORS_OF_PERIOD,
  WDP_LASTMONTH: PAGE_COMPARE_MONTH_MORE_SELECTORS_OF_PERIOD,
  WDP_THISQUARTER: PAGE_COMPARE_QUARTER_MORE_SELECTORS_OF_PERIOD,
  WDP_LASTQUARTER: PAGE_COMPARE_QUARTER_MORE_SELECTORS_OF_PERIOD,
  WDP_THISYEAR: PAGE_COMPARE_YEAR_MORE_SELECTORS_OF_PERIOD,
  WDP_LASTYEAR: PAGE_COMPARE_YEAR_MORE_SELECTORS_OF_PERIOD,
  WDP_MONTHTODATE: [
    {
      label: "SamePeriod PreviousMonth",
      value: "WDP_SAMEPERIOD_PREVIOUSMONTH"
    }
  ],
  WDP_QUARTERTODATE: [
    {
      label: "SamePeriod PreviousQuarter",
      value: "WDP_SAMEPERIOD_PREVIOUSQUARTER"
    }
  ],
  WDP_YEARTODATE: [
    {
      label: "SamePeriod PreviousYear",
      value: "WDP_SAMEPERIOD_PREVIOUSYEAR"
    }
  ],
  WDP_PAST7DAYS: [
    {
      label: "WDP_PREVIOUSPERIOD_PAST7DAYS",
      value: "WDP_PREVIOUSPERIOD_PAST7DAYS"
    }
  ],
  WDP_PAST2WEEKS: [
    {
      label: "WDP_PREVIOUSPERIOD_PAST2WEEKS",
      value: "WDP_PREVIOUSPERIOD_PAST2WEEKS"
    }
  ],
  WDP_PASTMONTH: [
    {
      label: "WDP_PREVIOUSPERIOD_PASTMONTH",
      value: "WDP_PREVIOUSPERIOD_PASTMONTH"
    }
  ],
  WDP_PAST3MONTH: [
    {
      label: "WDP_PREVIOUSPERIOD_PAST3MONTH",
      value: "WDP_PREVIOUSPERIOD_PAST3MONTH"
    }
  ],
  WDP_PAST6MONTH: [
    {
      label: "WDP_PREVIOUSPERIOD_PAST6MONTH",
      value: "WDP_PREVIOUSPERIOD_PAST6MONTH"
    }
  ],
  WDP_PAST12MONTH: [
    {
      label: "WDP_PREVIOUSPERIOD_PAST12MONTH",
      value: "WDP_PREVIOUSPERIOD_PAST12MONTH"
    }
  ]
};

/**
 * Period Relative lengend text
 *
 * **/
export const PAGE_PERIOD_LENGEND_TEXT = (key, t) => {
  if (key === "WDP_CUSTOM_PERIOD") {
    return function (startTime, endTime) {
      return moment(startTime * 1000).format("YYYY-MM-DD") + "~" + moment(endTime * 1000).format("YYYY-MM-DD");
    };
  } else {
    return t("dashboardPage.period." + key) || t("dashboardPage.compare." + key);
  }
  /*
  WDP_TODAY: "Today",
  WDP_YESTERDAY: "Yesterday",
  WDP_THISWEEK: "This week",
  WDP_LASTWEEK: "Last week",
  WDP_LAST2WEEK: "Last two weeks",
  WDP_THISMONTH: "This month",
  WDP_LASTMONTH: "Last month",
  WDP_LAST7DAYS: "Last 7 days",///////////////
  WDP_LAST30DAYS: "Last 30 days",//////////////
  WDP_PREVIOUS_DAY: "Previous day",
  WDP_THISQUARTER: "This quarter",
  WDP_LASTQUARTER: "Last quarter",
  WDP_THISYEAR: "This year",
  WDP_LASTYEAR: "Last year",
  WDP_MONTHTODATE: "Month to date",
  WDP_QUARTERTODATE: "Quarter to date",
  WDP_YEARTODATE: "Year to date",
  WDP_PAST7DAYS: "Past 7 days",
  WDP_PAST2WEEKS: "Past 2 weeks",
  WDP_PASTMONTH: "Past 1 month",
  WDP_PAST3MONTH: "Past 3 month",
  WDP_PAST6MONTH: "Past 6 month",
  WDP_PAST12MONTH: "Past 12 month",
  WDP_PREVIOUSDAY: "Previous day",
  WDP_PREVIOUSWEEK: "Previous week",
  WDP_PREVIOUSMONTH: "Previous month",
  WDP_PREVIOUSQUARTER: "Previous quarter",
  WDP_PREVIOUSYEAR: "Previous year",
  WDP_SAMEDAY_LASTWEEK: "Same day last week",
  // WDP_SAME_DAY_TWO_WEEKS_BEFORE: "Same day two weeks before",
  // WDP_SAME_DAY_LAST_MONTH: "Same day(s) last month",
  // WDP_A_WEEK_AGO: "A week ago",
  // WDP_TWO_WEEKS_AGO: "Two weeks ago",
  // WDP_PREVIOUS_TWO_WEEKS: "Previous two weeks",//////////////
  */
};

/**
 * Widget unit by source type
 *
 * **/
export const WIDGET_UNIT_BY_SOURCE_TYPE = {
  WET_ELECTRICITY: "kWh",
  WET_WATER: "L",
  WET_TEMPERATURE: "℃",
  TEMPERATURE: "℃",
  ENERGY: "kWh",
  E_COST: "$",
  E_CO2: "Kg",
  E_EUI: "kWh/m",
  E_BURGER: "Burgers",
  E_TREE: "m",
  E_TREE_PROTECTED: "protected",
  E_BREAD: "Buns",
  E_TAXI: "Cars",
  E_FOOT: "Trips",
  E_TEA: "Cups",
  E_RAMEN: "Bowls",
  WATER: "Litres",
  W_COST: "$",
  W_CO2: "Kg",
  W_EUI: "L/m",
  IAQ: "ALL",
  IAQ_TEMPERATURE: "°C",
  IAQ_HUMIDITY: "%",
  IAQ_TVOC: "μg/m³",
  IAQ_CO2: "ppm",
  IAQ_PM25: "μg/m³"
};
export const UNIT_TO_COST_VALUE = (unit, value, flag = false) => {
  switch (unit) {
    case "ENERGY":
    case "WATER":
    case "E_CO2":
    case "E_EUI":
    case "W_CO2":
    case "W_EUI":
      if (!flag) {
        return ToRoundDecimal(value).toLocaleString();
      } else {
        return ToRoundDecimal(value).toLocaleString() + " " + WIDGET_UNIT_BY_SOURCE_TYPE[unit] + UNIT_TO_SQUARE(unit);
      }
      break;
    case "E_COST":
      if (!flag) {
        return ToRoundDecimal(value).toLocaleString();
      } else {
        return WIDGET_UNIT_BY_SOURCE_TYPE[unit] + "" + ToRoundDecimal(value).toLocaleString();
      }
      break;
    case "E_BURGER":
    case "E_TAXI":
    case "E_FOOT":
    case "E_TEA":
    case "E_RAMEN":
    case "E_BREAD":
    case "E_TREE":
      if (!flag) {
        return ToRoundDecimal(value * UNIT_RATE_OF_SCHOOL_STUDENT[unit]).toLocaleString();
      } else {
        return ToRoundDecimal(value * UNIT_RATE_OF_SCHOOL_STUDENT[unit]).toLocaleString() + " " + WIDGET_UNIT_BY_SOURCE_TYPE[unit] + UNIT_TO_SQUARE(unit);
      }
      break;
    case "W_COST":
      if (!flag) {
        return ToRoundDecimal(value).toLocaleString();
      } else {
        return WIDGET_UNIT_BY_SOURCE_TYPE[unit] + "" + ToRoundDecimal(value).toLocaleString();
      }
      break;
    case "TEMPERATURE":
    case "IAQ":
    case "IAQ_TEMPERATURE":
    case "IAQ_HUMIDITY":
    case "IAQ_TVOC":
    case "IAQ_CO2":
    case "IAQ_PM25":
      if (!flag) {
        return ToRoundDecimalTemperature(value).toLocaleString();
      } else {
        return ToRoundDecimalTemperature(value).toLocaleString() + "" + WIDGET_UNIT_BY_SOURCE_TYPE[unit];
      }
      break;
    default:
      break;
  }
};
export const UNIT_METRIC_BY_SOURCE = (source, unit) => {
  if (source == "WET_ELECTRICITY") {
    switch (unit) {
      case "ENERGY":
        return "ENERGY";
      case "COST":
        return "E_COST";
      case "CO2":
        return "E_CO2";
      case "EUI":
        return "E_EUI";
      default:
        return "ENERGY";
    }
  } else if (source == "WET_WATER") {
    switch (unit) {
      case "WATER":
        return "WATER";
      case "COST":
        return "W_COST";
      case "CO2":
        return "W_CO2";
      case "EUI":
        return "W_EUI";
      default:
        return "WATER";
    }
  } else if (source == "WET_TEMPERATURE" || source == "WET_IAQ") {
    switch (unit) {
      case "TEMPERATURE":
        return "IAQ_TEMPERATURE";
      case "HUMIDITY":
        return "IAQ_HUMIDITY";
      case "TVOC":
        return "IAQ_TVOC";
      case "CO2":
        return "IAQ_CO2";
      case "PM25":
        return "IAQ_PM25";
    }
  }
};
export const UNIT_TO_SQUARE = unit => {
  if (unit.indexOf("EUI") != -1) {
    return "\u00B2";
  } else if (unit.indexOf("E_TREE") != -1) {
    return "\u00B2 " + WIDGET_UNIT_BY_SOURCE_TYPE["E_TREE_PROTECTED"];
  }
  return "";
};
export const UNIT_COST_TO_YAXIS_VALUE = (unit, value) => {
  if (unit == "W_COST") {
    return value >= 1000 ? Math.round(value / 1000).toLocaleString() + "k" : ToRoundDecimal(value);
  } else if (unit == "E_COST") {
    return value >= 1000 ? Math.round(value / 1000).toLocaleString() + "k" : ToRoundDecimal(value);
  }
  return value >= 1000 ? Math.round(value / 1000).toLocaleString() + "k" : ToRoundDecimal(value);
};

/**
 * UNIT RATE OF SCHOOLSTUDENT
 * */
export const SCHOOL_UNIT_BY_TYPE = {
  ENERGY: [
    { id: v4(), _class: "kwhTabIcon", label: "Energy", key: "kwh", unit: "ENERGY" },
    { id: v4(), _class: "moneyTabIcon", label: "Money", key: "money", unit: "E_COST" },
    { id: v4(), _class: "co2TabIcon", label: "Carbon", key: "co2", unit: "E_CO2" },
    { id: v4(), _class: "burgerTabIcon", label: "Hamburger", key: "burger", unit: "E_BURGER" },
    { id: v4(), _class: "pineappleTabIcon", label: "Pineapple bun", key: "bread", unit: "E_BREAD" },
    { id: v4(), _class: "bubbleTeaTabIcon", label: "Bubble tea", key: "tea", unit: "E_TEA" },
    { id: v4(), _class: "ramenTabIcon", label: "Noodles", key: "ramen", unit: "E_RAMEN" },
    { id: v4(), _class: "treeTabIcon", label: "Rainforest", key: "tree", unit: "E_TREE" },
    { id: v4(), _class: "taxiTabIcon", label: "Cars", key: "taxi", unit: "E_TAXI" },
    { id: v4(), _class: "footTabIcon", label: "Walking", key: "foot", unit: "E_FOOT" }
  ],
  WATER: [
    { id: v4(), _class: "kwhTabIcon", label: "Litres", key: "WATER", unit: "WATER" },
    { id: v4(), _class: "moneyTabIcon", label: "Money", key: "money", unit: "W_COST" },
    { id: v4(), _class: "co2TabIcon", label: "Carbon", key: "co2", unit: "W_CO2" }
  ],
  TEMPERATURE: []
};

/**
 * UNIT RATE OF SCHOOLSTUDENT
 * */
export const UNIT_RATE_OF_SCHOOL_STUDENT = {
  kwh: null,
  money: null,
  co2: null,
  E_BURGER: 1.56,
  E_TREE: 0.041,
  E_TAXI: 0.092,
  E_FOOT: 0.714,
  E_TEA: 5.21,
  E_RAMEN: 1.65,
  E_BREAD: 3.3
};
/**
 * TEMPERATURE EXPECTION VALUE
 * */
export const EXPECTION_VALUE = -65535;
/**
 *  EXPECTION VALUE
 * */
// export const ENERGY_UNIT_TO_COST = 1.031;
/**
 * WATER_UNIT_TO_COST
 * */
// export const WATER_UNIT_TO_COST = 7500;
